import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO002Banner.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO002Overview.js"



export default function PRO002_Intro() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
      <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO002 - Hydroponic Farm</h1>
      <br />
      <h2 style={{color: "white"}}>Project introduction</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>Intro</h2>
        <p>Groing plants hydroponically is basically without using soil. The roots of the plants are being exposed to a nutrient, which is a water based fluid that contains all the food the plant is going to need. <br />
        The benefit is that this way plants can be grown in more severe environents and even indoors. Another benfit is a drastic decrease in the amount of water needed for cultivating a plant and 'faster' groing times, since the plant does not need to invest as much energy to cultivating the root system.</p>
        <p>There are a lot of <a href="https://www.nosoilsolutions.com/6-different-types-hydroponic-systems/">different types of hydroponic farming</a>, and the type that speaks to me the most is the ebb and flood type. A crude sketch of what I am imagining:
        </p>
        <figure>
        <img width="60%"  src={background} style={{borderRadius: 20, marginLeft: "20%" , marginRight: "20%"}} alt="Mechanism bell" />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Crude sketch of a possible hydroponic system.</figcaption>
        </figure>
        <h2>What I think I need</h2>
        <br/>
        <p><ul>
          <li><b>10 cm tube:</b> To keep the plants and pass through the nutrient. The plan is to start with a single T-junction to use as a setup for my proof of concept. </li>
          <li><b>pump:</b> To flush the nutrient through the tube.</li>
          <li><b>Hose tail:</b> One or two to drain and fill the pipe with nutrient.</li>
          <li><b>Hoses:</b> To connect everything.</li>
          <li><b>UV light:</b> Shall provide natural light, in case I want to grow the plants in my basement or winter times.</li>
          <li><b>Controller:</b> For controlling the pump, valve and the light cycle. Looking forward to try something else than an Arduino, I'll opt to use an ESP8266 controller board.</li>
          <li><b>Relais:</b> 1-3 pending on the type of pump, valve and lights.</li>
          <li><b>Net cup:</b> for growing the medium in, still thinking about what would be the better medium.</li>
          <li><b>Ph tester:</b> For optimal intake of nutrients, the fluid should have a Ph <a href="https://www.greenandvibrant.com/ph-hydroponics"> between 5.5 and 6.5</a>.</li>
          <li><b>Vent:</b> To provide a breeze, removing the moisture from the leaves to avoid fungi. Thinking about it, this shall only be a lesser priority.</li>
          </ul></p>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}